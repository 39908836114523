import './index.css';
import 'assets/scss/style.scss';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import themes from './themes';

import { SnackbarProvider } from 'notistack';

import Routes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => (
  <ThemeProvider theme={themes()}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <Routes />
      </SnackbarProvider>
    </LocalizationProvider>
  </ThemeProvider>
);

export default App;
