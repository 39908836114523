import { lazy } from 'react';

// project imports
import Loadable from 'ui-components/loadhandlers/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const Onboarding = Loadable(lazy(() => import('views/onboarding')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const OnboardingRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/onboarding',
      element: <Onboarding />
    }
  ]
};

export default OnboardingRoutes;
