import PropTypes from 'prop-types';

// import { useCallback, useEffect, useState } from "react";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// project imports
// import LogoSection from "../LogoSection";
// import HelpSection from "./HelpSection";
// import userTypes from "constants/userTypes";

// redux

// import ProfileSection from "./ProfileSection";
// import SettingsSection from "./SettingsSection";
// import NotificationSection from "./NotificationSection";
// import headerRightBrand from "assets/images/brand/header_right_logo.svg";

// assets
// import { IconMenu2 } from "@tabler/icons-react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, my: 2 }} onClick={handleLeftDrawerToggle}>
          <img
            // src="src\assets\DT-logo.png"
            src="https://driptours.com/wp-content/themes/picostrap5-child-base/assets/images/DT-logo.png"
            className="img-fluid"
            alt="Logo"
            style={{ maxWidth: '100%', height: 'auto', paddingTop: 10 }}
          />
        </Box>
        <Box
          // component="span"
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'space-between',
            alignItems: 'center',
            // flexGrow: 1,
            my: 2,
            width: '90vw'
          }}
          onClick={handleLeftDrawerToggle}
        >
          <Box
            component="img"
            src="https://driptours.com/wp-content/themes/picostrap5-child-base/assets/images/DT-logo.png"
            // className="img-fluid"
            alt="Logo"
            sx={{ maxWidth: 200 }}
            // style={{ maxWidth: 200, height: "auto", paddingTop: 10 }}
          />
          <IconButton>
            <MenuIcon color="primary" />
          </IconButton>
        </Box>
        {/* <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              "&:hover": {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
            onClick={handleLeftDrawerToggle}
          ></Avatar>
        </ButtonBase> */}
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* <IntroVideoSection /> */}
      {/* <Box sx={{ mr: 4, mb: -0.5 }}>
        <img
          src={headerRightBrand}
          style={{ height: 30, filter: "grayscale(30%)" }}
          alt="header right logo"
        />
      </Box> */}
      <Stack direction="row" alignItems="center" gap={1.5} sx={{ mt: 1.5 }}>
        {/* <NotificationSection
          unreadNotifCount={unreadNotifCount}
          getUnreadNotifCount={getUnreadNotifCount}
        />
        <HelpSection />
        {userData?.type === userTypes.Admin && <SettingsSection />}
        <ProfileSection /> */}
      </Stack>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
