import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DrawerContext from 'contexts/DrawerContext';

const customizationConfig = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12
};

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  const { leftDrawerOpened: opened, setLeftDrawerOpened } = useContext(DrawerContext);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

  // if there are multiple pathname then get only the first one
  const mainPath = pathname.split('/').length > 2 ? `/${pathname.split('/')[1]}` : pathname;

  const isNavItemSelected = mainPath === item.url;

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon stroke={1.5} size="1.3rem" color={isNavItemSelected ? 'white' : 'inherit'} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: 6,
        height: 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const listItemProps = {
    // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={`${customizationConfig.basename}${item.url}`} target={itemTarget} />
    ))
  };

  const itemHandler = () => {
    if (matchesSM) setLeftDrawerOpened(false);
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: '12px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`
      }}
      selected={isNavItemSelected}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon
        sx={{
          my: 'auto',
          minWidth: !item?.icon ? 18 : 36,
          color: isNavItemSelected ? 'white' : 'inherit'
        }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          opened ? (
            <Typography sx={{ textWrap: 'nowrap' }} variant={isNavItemSelected ? 'h5' : 'body1'} color="inherit">
              {item.title}
            </Typography>
          ) : (
            ''
          )
        }
        secondary={
          item.caption && opened ? (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption, textWrap: 'nowrap' }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          ) : (
            ''
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  opened: PropTypes.bool
};

export default NavItem;
