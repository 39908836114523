import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
// import sideBarBrand from 'assets/images/brand/sidebar_brand.png';
// import sideBarBrandLogo from 'assets/images/brand/sidebar_brand_logo.png';
// import LogoSection from '../LogoSection';
// import MenuCard from './MenuCard';
// import { setMenu } from 'store/features/customizationSlice';

const drawerWidth = 260;
const collapseDrawerWidth = 100;

const DrawerStyled = styled('div')(({ theme }) => ({
  background: 'white',
  width: collapseDrawerWidth,
  position: 'sticky',
  top: 80,

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),

  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    top: 0,
    left: -drawerWidth,
    bottom: 0,
    zIndex: 2000,
    width: drawerWidth,
    boxShadow: theme.shadows[6],
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  variants: [
    {
      props: ({ open }) => open,

      style: {
        width: drawerWidth
      }
    },
    {
      props: ({ open }) => open,

      style: {
        [theme.breakpoints.down('lg')]: {
          left: 0
        }
      }
    }
  ]
}));

const DrawerOverlay = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(9, 0, 0, 0.7)',
    zIndex: 1900,
    opacity: 0,
    visibility: 'hidden',
    transition: theme.transitions.create(['opacity', 'visibility'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  variants: [
    {
      props: ({ open }) => open,

      style: {
        [theme.breakpoints.down('lg')]: {
          opacity: 1
        }
      }
    },
    {
      props: ({ open }) => open,

      style: {
        [theme.breakpoints.down('lg')]: {
          visibility: 'visible'
        }
      }
    }
  ]
}));

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>{/* <LogoSection /> */}</Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}
        >
          <MenuList drawerOpen={drawerOpen} />
          {/* <MenuCard /> */}

          {/* Brand */}
          {/* {drawerOpen ? (
            <Box sx={{ width: 'fit-content', mx: 'auto' }}>
              <img src={sideBarBrand} alt="sidebar brand" />
            </Box>
          ) : (
            <Box sx={{ width: 'fit-content', mx: 'auto' }}>
              <img src={sideBarBrandLogo} style={{ width: 40, height: 40 }} alt="sidebar brand logo" />
            </Box>
          )} */}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}
        >
          <MenuList />
        </PerfectScrollbar>
      </MobileView>
    </>
  );
  return (
    <>
      <div>
        <DrawerStyled open={drawerOpen}>{drawer}</DrawerStyled>
      </div>
      <DrawerOverlay open={drawerOpen} onClick={() => drawerToggle(false)} />
    </>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func
};

export default Sidebar;
