// react-router-dom
import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import UserRoutes from './UserRoutes';
import OnboardingRoutes from './OnboardingRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ isAuthenticated = true }) {
  // public routes
  let routes = [AuthenticationRoutes];

  if (isAuthenticated) {
    routes = [...routes, UserRoutes, OnboardingRoutes];
  }

  return useRoutes(routes);
}
