/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

// The amount to increase the font size once we break up small devices.
// This occurs at min-width: 600px which is equvalent to 'sm' breakpoint in mui.
export const multiplier = 1.0625;

export default function themeTypography(theme) {
  return {
    fontFamily: '"Outfit", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h6: {
      fontWeight: 500,
      color: theme.heading,
      fontSize: '0.75rem',
      '@media (min-width:600px)': {
        fontSize: `calc(0.75rem * ${multiplier})`
      }
    },
    h5: {
      fontSize: '0.875rem',
      color: theme.heading,
      fontWeight: 500,
      '@media (min-width:600px)': {
        fontSize: `calc(0.875rem * ${multiplier})`
      }
    },
    h4: {
      fontSize: '1rem',
      color: theme.heading,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: `calc(1rem * ${multiplier})`
      }
    },
    h3: {
      fontSize: '1.25rem',
      color: theme.heading,
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: `calc(1.25rem * ${multiplier})`
      }
    },
    h2: {
      fontSize: '1.5rem',
      color: theme.heading,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: `calc(1.5rem * ${multiplier})`
      }
    },
    h1: {
      fontSize: '2.125rem',
      color: theme.heading,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: `calc(2.125rem * ${multiplier})`
      }
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.textDark,
      '@media (min-width:600px)': {
        fontSize: `calc(0.875rem * ${multiplier})`
      }
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: theme.darkTextSecondary,
      '@media (min-width:600px)': {
        fontSize: `calc(0.75rem * ${multiplier})`
      }
    },
    caption: {
      fontSize: '0.75rem',
      color: theme.darkTextSecondary,
      fontWeight: 400,
      '@media (min-width:600px)': {
        fontSize: `calc(0.75rem * ${multiplier})`
      }
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.334em',
      '@media (min-width:600px)': {
        fontSize: `calc(0.875rem * ${multiplier})`
      }
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
      color: theme.darkTextPrimary
    },
    body3: {
      letterSpacing: '0em',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5em',
      color: theme.darkTextPrimary
    },
    button: {
      textTransform: 'capitalize'
    },
    mainContent: {
      backgroundColor: theme.background,
      width: '100%',
      // minHeight: 'calc(100vh - 88px)',
      flexGrow: 1,
      padding: '20px',
      // marginTop: '88px',
      // marginRight: '20px',
      borderRadius: `${theme?.customization?.borderRadius}px`
    },
    menuCaption: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.heading,
      padding: '6px',
      textTransform: 'capitalize',
      marginTop: '10px',
      '@media (min-width:600px)': {
        fontSize: `calc(0.875rem * ${multiplier})`
      }
    },
    subMenuCaption: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: 'capitalize',
      '@media (min-width:600px)': {
        fontSize: `calc(0.6875rem * ${multiplier})`
      }
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px'
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: `calc(1rem * ${multiplier})`
      }
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem',
      '@media (min-width:600px)': {
        fontSize: `calc(1.2rem * ${multiplier})`
      }
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: `calc(1.5rem * ${multiplier})`
      }
    }
  };
}
