const handleNavigateToHome = () => {
  window.location.assign(import.meta.env.VITE_APP_WP_HOME);
};

const handleNavigateToLogin = () => {
  window.location.assign(`${import.meta.env.VITE_APP_WP_HOME}/login`);
};

const handleNavigateToSignUp = () => {
  window.location.assign(`${import.meta.env.VITE_APP_WP_HOME}/signup`);
};

export { handleNavigateToHome, handleNavigateToLogin, handleNavigateToSignUp };
