import { useState, useMemo, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import PropTypes from 'prop-types';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, CssBaseline, useMediaQuery, AppBar, Toolbar } from '@mui/material';

// project imports
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import DrawerContext from 'contexts/DrawerContext';

// import { drawerWidth } from "store/constant";
const drawerWidth = 260;
// const SPACING = 8;
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  ...theme.typography.mainContent,

  variants: [
    {
      props: ({ open }) => !open,

      style: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
          width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          padding: '16px',
          marginRight: '10px'
        }
      }
    },
    {
      props: ({ open }) => open,

      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`
      }
    }
  ]
}));

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  // Handle left drawer
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(!matchDownMd);
  const [isLoading, setIsLoading] = useState(false);

  const handleLeftDrawerToggle = useCallback(() => {
    setLeftDrawerOpened((prev) => !prev);
  }, []);

  const contextValue = useMemo(
    () => ({
      leftDrawerOpened,
      setLeftDrawerOpened,
      isLoading,
      setIsLoading
    }),
    [leftDrawerOpened, setLeftDrawerOpened, isLoading, setIsLoading]
  );

  return (
    <>
      <DrawerContext.Provider value={contextValue}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* header */}

          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
              bgcolor: theme.palette.background.default,
              transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
              borderBottom: '1.5px solid rgb(255,20,147)'
            }}
          >
            <Toolbar sx={{ height: 80 }}>
              <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </Toolbar>
          </AppBar>

          <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
          {/* <AppBar /> 
        {/* main content */}
          <Main open={leftDrawerOpened}>
            <Box mt={10}>
              <Outlet />
            </Box>
          </Main>
        </Box>
      </DrawerContext.Provider>
    </>
  );
};

export default MainLayout;
