// redux
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// redux-persist
import { persistStore, persistReducer, createMigrate, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducers
import userReducer, { initialState as initialStateUser } from './features/userSlice';

// ==============================|| REDUX - MAIN STORE ||============================== //

// items to reset when a redux reset is requested i.e. on logout
const resetSlices = ['customization', 'respondent', 'user', 'triggerAlertBanner'];

// items to persist.
// persisting items will allow them to work between multiple tabs, or across multiple sessions
// ensure any items you do not want to persist after a session is complete are added to the resetSlices above
const persistSlices = ['user', 'appConfig', 'calendarFilters'];

// redux persist migrations
// https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
const migrations = {
  // initial migration
  0: (state) => ({
    ...state,
    // reset all persisted slices
    user: initialStateUser
  })
  // add more migrations as needed
  // ...
};

const persistConfig = {
  key: 'root',
  // set version to latest migration number
  version: 3,
  migrate: createMigrate(migrations, { debug: false }),
  // save to local storage, other storage options https://github.com/rt2zz/redux-persist#storage-engines
  storage,
  whitelist: persistSlices
};

const rootReducer = combineReducers({
  user: userReducer
});

const resetActionReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    for (const sliceName of resetSlices) {
      state[sliceName] = undefined;
    }
  }
  return rootReducer(state, action);
};

// enhanced reducer with configuration to persist the state
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const persistedReducer = persistReducer(persistConfig, resetActionReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export { store, persistor };
